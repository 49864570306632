import React from 'react';
import {Helmet} from "react-helmet";
import Favicon from '../../images/favicon.svg';
import {
    DMSansBoldTTF,
    DMSansBoldWOFF,
    DMSansMediumTTF,
    DMSansMediumWOFF,
    DMSansRegularTTF,
    DMSansRegularWOFF
} from '../fonts';

const DEFAULT_TITLE = 'Swaptecs';

const PageHead = ({title}) => {
    return <Helmet>
        <title>{title ? title : DEFAULT_TITLE}</title>
        <link rel="icon" href={'../../images/favicon.svg'}/>
        <link rel="icon" href={'../../images/favicon.png'}/>
        <link rel="icon" href={'../../images/favicon.ico'}/>
        <link rel="icon" href={Favicon}/>

        <style>
            {`
                @font-face {
                    font-family: "DMSR";
                    src:
                        local("DMSR"),
                        url(${DMSansRegularTTF}) format('woff'),
                        url(${DMSansRegularWOFF}) format('truetype');
                }
                
                @font-face {
                    font-family: "DMSM";
                    src:
                        local("DMSM"),
                        url(${DMSansMediumTTF}) format('woff'),
                        url(${DMSansMediumWOFF}) format('truetype');
                }
                
                @font-face {
                    font-family: "DMSB";
                    src:
                        local("DMSB"),
                        url(${DMSansBoldTTF}) format('woff'),
                        url(${DMSansBoldWOFF}) format('truetype');
                }
            `}
        </style>
    </Helmet>
}

export default PageHead;