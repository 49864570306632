export const APP_URL = 'https://swaptecs.app/'

export const CONTACT_COLLECTION_URL = 'websiteContacts'

export const REACH_OUTS_TYPES = {
    CONTACT: "CONTACT",
    BULK_CONTACT: "BULK_CONTACT"
}

export const INTERNAL_URLS = {
    HOME: '/',
    TRACK_ORDER: '/track-order',
    HOW_IT_WORKS: '/how-it-works',
    FAQ: '/faq',
    SELL_IN_BULK: '/sell-in-bulk',
    HOW_TO_SELL: '/how-to-sell',
    BLOG: '/blog',
    ABOUT_US: '/about-us',
    CONTACT: '/contact',
    CART: '/cart',
    PRESS_RESOURCES: '/press-resources',
    PRIVACY_POLICY: '/privacy-policy',
    TERMS_AND_CONDITIONS: '/terms-and-conditions'
}

export const NAV_LINKS = [
    {
        href: '/how-it-works',
        title: 'How it works'
    },
    {
        classes: 'pc-hidden',
        href: '/faq',
        title: 'FAQ',
    },
    {
        href: '/sell-in-bulk',
        title: 'Sell in bulk'
    },
    {
        classes: 'mobile-hidden',
        href: '/faq',
        title: 'FAQ',
    },
    {
        classes: 'pc-hidden',
        href: '/how-to-sell',
        title: 'How to sell'
    },
    {
        classes: 'pc-hidden',
        href: '/contact',
        title: 'Contact'
    },
    {
        classes: 'hidden',
        href: '/about-us',
        title: 'About us'
    },
    {
        classes: 'hidden',
        href: '/blog',
        title: 'Blog'
    },
    {
        classes: 'mobile-hidden',
        href: '/track-order',
        title: 'Track order'
    },
];
