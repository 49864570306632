import React from 'react';
import './index.scss';
import Logo from "../../images/logo.svg";
import {Button} from "../common";
import {APP_URL, INTERNAL_URLS} from "../../data/consts";
import {openUrl} from "../../utils";

const Footer = () => {
    return <footer>
        <div className="footer-content">
            <div className="info-section">
                <img src={Logo} alt="Swaptecs Logo" onClick={() => openUrl(INTERNAL_URLS.HOME)}/>
                <p>
                    Exchange your old device for money<br/>
                    as easy as possible
                </p>

                <div className="stats">
                    <div className="usd-paid">
                        <b>$ 182.156</b> <small>paid</small>
                    </div>
                    <div className="phones-bought">
                        <b>1 167</b> <small>phones bought</small>
                    </div>
                </div>

                <div className="buttons">
                    <Button
                        className={'primary'}
                        label={'Sell your phone'}
                        onClick={() => openUrl(APP_URL)}
                    />

                    <Button
                        label={'Track your order'}
                        onClick={() => openUrl(INTERNAL_URLS.TRACK_ORDER)}
                    />
                </div>
            </div>

            <div className="map">
                <div>
                    <h5>
                        Product
                    </h5>
                    <ul>
                        <li><a href={INTERNAL_URLS.HOW_IT_WORKS}>How it works</a></li>
                        <li><a href={INTERNAL_URLS.HOW_TO_SELL}>How to sell</a></li>
                        <li><a href={INTERNAL_URLS.SELL_IN_BULK}>Sell in bulk</a></li>
                        <li><a href={INTERNAL_URLS.PRESS_RESOURCES}>Press resources</a></li>
                    </ul>
                </div>

                <div>
                    <h5>
                        Company
                    </h5>
                    <ul>
                        <li><a href={INTERNAL_URLS.ABOUT_US}>About us</a></li>
                        <li><a href={INTERNAL_URLS.CONTACT}>Contact</a></li>
                        <li><a href={INTERNAL_URLS.BLOG}>Blog</a></li>
                        <li><a href={INTERNAL_URLS.FAQ}>FAQ</a></li>
                    </ul>
                </div>

                <div>
                    <h5>
                        Connect
                    </h5>
                    <ul>
                        <li><a href="">Facebook</a></li>
                        <li><a href="">Instagram</a></li>
                        <li><a href="">Twitter</a></li>
                        <li><a href="">Telegram</a></li>
                    </ul>
                </div>
            </div>
        </div>
        <div className="footer-copyright">
            <div className="policy-and-terms">
                <a href={INTERNAL_URLS.PRIVACY_POLICY}>Privacy Policy</a>
                <a href={INTERNAL_URLS.TERMS_AND_CONDITIONS}>Terms and Conditions</a>
            </div>
            <div className="copyright">
                © 2020 Swaptecs. All rights reserved
            </div>
        </div>
    </footer>
}

export default Footer;