import React from 'react';
import './index.scss';

const Device = ({data, onClick}) => {
    const {name, imageUrl, price} = data;
    return <div className='phoneContainer' onClick={onClick}>
        <div className="imageContainer">
            <img src={require(`../../../images/phones/${imageUrl}`).default} alt={name} />
        </div>
        <p className={'name weight-medium'}>{name}</p>
        <p className={'price'}>Get up to ${price}</p>
    </div>
};

export default Device;
