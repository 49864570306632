import React, {useEffect, useState} from 'react';
import {PageHead} from "../";
import Logo from '../../images/logo.svg';
import WhiteLogo from '../../images/logo_white.svg';
import Hamburger from '../../images/icons/hamburgher.inline.svg';
import Cross from '../../images/icons/cross.inline.svg';
import Cart from '../../images/icons/cart.inline.svg';
import {Button} from "../common";
import './index.scss';
import {APP_URL, INTERNAL_URLS, NAV_LINKS} from "../../data/consts";
import {openUrl} from "../../utils";

const Nav = ({title, location, darkNav = false}) => {
    const [menuOpen, setMenuOpen] = useState(false);
    const [isScrolled, setScrolled] = useState(false);
    const isLinkActive = (href) => location && location.pathname.indexOf(href) > -1;

    const onscroll = () => {
        document.documentElement.scrollTop > 100 ? setScrolled(true) : setScrolled(false);
    }

    useEffect(() => {
        document.addEventListener('scroll', onscroll);
        return () => document.removeEventListener('scroll', onscroll);
    })

    return <>
        <PageHead title={title}/>

        <nav className={`${menuOpen ? 'active' : ''} ${darkNav || isScrolled ? 'scrolled-menu' : ''}`}>
            <div className="content-container">
                <div className="logo-container" onClick={() => openUrl(INTERNAL_URLS.HOME)}>
                    <img className={'pc-hidden'} src={darkNav || menuOpen || isScrolled ? Logo : WhiteLogo}
                         alt="Swaptecs Logo"/>
                    <img className={'mobile-hidden'} src={darkNav || isScrolled ? Logo : WhiteLogo}
                         alt="Swaptecs Logo"/>
                </div>

                <ul className="nav-links">
                    <li className='buttons-container pc-hidden'>
                        <Button
                            onClick={() => openUrl(INTERNAL_URLS.TRACK_ORDER)}
                            label={'Track order'}
                            className={'fixed-width'}
                        />
                        <Button
                            onClick={() => openUrl(APP_URL)}
                            label={'Sell my phone'}
                            className={'secondary fixed-width'}
                        />
                    </li>
                    <li className={'pc-hidden'}>
                        <a
                            href={INTERNAL_URLS.CART}
                            className={'with-icon'}
                        >
                        <span className="icon">
                            <Cart/>
                        </span>
                            <span className={'color-casper'}>$0</span>
                        </a>
                    </li>
                    {NAV_LINKS.map(({title, href, classes}) => <li
                        key={title + classes}
                        className={`${isLinkActive(href) ? 'active' : ''} ${classes ? classes : ''}`}
                    >
                        <a href={href}>{title}</a>
                    </li>)}
                    <li className={'mobile-hidden'}>
                        <a
                            href={INTERNAL_URLS.CART}
                            className={'with-icon'}
                        >
                        <span className="icon">
                            <Cart/>
                        </span>
                            <span className={'color-casper'}>$0</span>
                        </a>
                    </li>
                    <li className={'mobile-hidden'}>
                        <Button
                            onClick={() => openUrl(APP_URL)}
                            label={'Sell my phone'}
                            className={'secondary'}
                        />
                    </li>
                </ul>

                <div
                    className={`
                        menu-button icon clickable pc-hidden 
                        ${darkNav || menuOpen || isScrolled ? 'dark-icon' : 'light-icon'}
                    `}
                    onClick={() => setMenuOpen(!menuOpen)}
                >
                    {menuOpen ? <Cross/> : <Hamburger/>}
                </div>
            </div>
        </nav>
    </>
}

export default Nav;