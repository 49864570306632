import React from 'react';
import './index.scss';

const Button = ({label, onClick, className, children}) => {
    return <button className={`${className ? className : ''}`} onClick={onClick}>
        {label && label}
        {children && children}
    </button>
}

export default Button;
